<template>
  <div class="applicationRecord">
    <!-- 申请记录公司详情 -->
    <ShowDetail v-if="showDetail" @closeDialog="closeDialog" ref="showDetail" />
    <!-- 申请记录农户详情 -->
    <ShowDetail2
      v-if="showDetail2"
      @closeDialog2="closeDialog2"
      ref="showDetail2"
    />
    <!-- 公司编辑 -->
    <ShowEdit v-if="showEdit" @closeDialog3="closeDialog3" ref="showEdit" />
    <!-- 农户编辑 -->
    <ShowEdit2 v-if="showEdit2" @closeDialog4="closeDialog4" ref="showEdit2" />
    <!-- 金融产品表格 -->
    <div class="form-list">
      <div class="table-search">
        <h3>金融产品申请记录</h3>
        <!-- 列表搜索条件，已封装 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
    </div>
    <div class="table-content">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelection"
      >

        <template slot="approveMoney" slot-scope="scope">
          <span>{{ scope.row.approveMoney }}万元</span>
        </template>
        <template slot="status" slot-scope="scope">
          <span>{{
            scope.row.status == 0
              ? "草稿"
              : scope.row.status == -1
              ? "驳回"
              : scope.row.status == 1
              ? "审批中"
              : "通过"
          }}</span>
        </template>
        <!-- scope.row.applyId -->
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="detailClick(scope.row)"
            >详情</el-button
          >
          <!-- scope.row.applyId, scope.row.financeProductId -->
          <el-button type="text" size="small" @click="editClick(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.status == '-1' || scope.row.status == '0'"
            type="text"
            size="small"
            @click="delClick(scope.row.applyId)"
            >删除</el-button
          >
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
const Query = [
  {
    name: "title",
    label: "产品名称",
    componentType: "input",
    inputType: "",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [],
  },
  {
    name: "departName",
    label: "机构名称：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },

  {
    name: "status",
    label: "放款状态",
    componentType: "select",
    placeholder: "请选择",
    width: "180px",
    dataOptions: [
      {
        name: "草稿",
        value: 0,
      },
      {
        name: "驳回",
        value: -1,
      },
      {
        name: "审批中",
        value: 1,
      },
      {
        name: "通过",
        value: 2,
      },
    ],
  },
];
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";

import ShowDetail from "./ApplicationRecordDetails";
import ShowDetail2 from "./FarmerApplicationRecordDetails";
import ShowEdit from "./applicationEdit";
import ShowEdit2 from "./FarmerApplicationEdit";
import { wytgColumn } from "./tableColumn";
import { ApplicationRecordList, DeleteApply } from "@/api/RuralFinance";
import { mapGetters } from "vuex";
export default {
  name: "AgriculturalAssistanceProject9Index",
  data() {
    return {
      Query: Query,
      tableData: [],
      columns: wytgColumn,
      loading: false,
      showDetail: false,
      showDetail2: false,
      showEdit: false,
      showEdit2: false,
      keyForm: 1,
      total: 0,
      query: {
        pnum: 1,
        psize: 10,
      },
    };
  },
  components: {
    FormQuery,
    TablePage,
    ShowDetail,
    ShowDetail2,
    ShowEdit,
    ShowEdit2,
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    this.ApplicationRecordList();
  },

  methods: {
    // 点击查看详情
    detailClick(val) {
      // scope.row.applyId

      if (val.userType == 2) {
        this.showDetail2 = true;
        this.$nextTick(() => {
          this.$refs.showDetail2.init(val.applyId);
        });
      } else {
        this.showDetail = true;
        this.$nextTick(() => {
          this.$refs.showDetail.init(val.applyId);
        });
      }
    },
    //显示申请记录列表
    async ApplicationRecordList() {
      this.loading = true;
      let params = {
        // identityId: this.identityObj.identityId,
        pnum: this.query.pnum,
        psize: this.query.psize,
      };
      let res = await ApplicationRecordList(params);
      if (!res.code) {
        // this.tableData = res.data;
        this.total = res.dataNum;
        this.loading = false;
      } else {
        this.$message.error("查询失败");
        this.loading = false;
      }
      this.tableData = res.data;
      this.total = res.dataNum;
    },

    // 关闭弹框
    closeDialog() {
      this.showDetail = false;
    },
    //关闭详情弹框页
    closeDialog2() {
      this.showDetail2 = false;
    },
    //关闭编辑页
    closeDialog3(showEdit) {
      this.showEdit = false;

      this.ApplicationRecordList();
    },
    //关闭农户编辑页
    closeDialog4() {
      this.showEdit2 = false;
      this.ApplicationRecordList();
    },

    handleSelection(selects) {
      console.log(selects);
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.ApplicationRecordList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.ApplicationRecordList();
    },
    //点击编辑按钮
    // editClick(applyId, financeProductId) {
    editClick(obj) {
      let departItem={
        departId:obj.departId,
        departName:obj.departName
      }

      // console.log(obj.applyId,'查看是否有applayid')
      let ids = {
        applyId: obj.applyId,
        financeProductId: obj.financeProductId,
        departItem:departItem,

      };
      if (obj.userType == 2) {
        this.showEdit2 = true;
        this.$nextTick(() => {
          this.$refs.showEdit2.editObj(ids);
        });
      } else {
        this.showEdit = true;
        this.$nextTick(() => {
          this.$refs.showEdit.editObj(ids);
        });
      }
    },
    //点击删除
    async delClick(id) {
      let res = await DeleteApply(id);
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await DeleteApply(id);
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.ApplicationRecordList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 点击搜索按钮
    async search(SearchItem) {
      let params = {
        // userId: this.identityObj.identityId,
        ...SearchItem,
        ...this.query,
      };
      console.log(params);
      let res = await ApplicationRecordList(params);
      this.tableData = res.data;
      this.total = res.dataNum;
      // this.query.pnum = 1;
      // // 组装条件
      // this.query = {
      //   ...this.query,
      //   ...params,
      //   ...{ cropCode: params.cropCode.join(",") },
      // };
    },
    submit() {},
    // 搜索条件重置按钮
    async reset() {
      this.query = {
        pnum: 1,
        psize: 10,
      };
      let params = {
        // userId: this.identityObj.identityId,
        ...this.query,
      };
      console.log(params);
      let res = await ApplicationRecordList(params);
      this.tableData = res.data;
      this.total = res.dataNum;
    },
  },
};
</script>

<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
  }
}
</style>
